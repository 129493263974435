@if (!loading) {
    <div class="container-fluid p-0 m-0">
        <app-header />

        <app-sponsor-shared-banner />

        <div class="step col-10 col-md-6 mx-auto my-5 p-5 text-center">

            <div class="row mt-3 mb-5">
                <div class="col px-md-5 text-center">
                    <p class="custom-text h2">Modifier mes informations personnelles</p>
                </div>
            </div>

            <form [formGroup]="sponsorForm">
                <div class="row my-2">
                    <div class="col">
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="gender">
                                <mat-option [value]="1">M.</mat-option>
                                <mat-option [value]="2">Mme.</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Prénom</mat-label>
                            <label class="d-none" for="firstName"></label>
                            <input formControlName="firstName"
                                   id="firstName"
                                   matInput
                                   maxlength="226"
                                   placeholder="Prénom *"
                                   type="text">
                            <em aria-hidden="true" class="fas fa-id-card me-2 text-secondary" matPrefix></em>
                        </mat-form-field>
                        <app-form-errors [control]="sponsorForm.controls.firstName" class="mt-1" />
                    </div>
                </div>

                <div class="row">
                    <div class="col mx-auto">
                        <mat-form-field>
                            <mat-label>Nom</mat-label>
                            <input formControlName="lastName"
                                   id="lastName"
                                   matInput
                                   placeholder="Nom"
                                   type="text">
                            <em aria-hidden="true" class="fas fa-id-card me-2 text-secondary" matPrefix></em>
                        </mat-form-field>
                        <app-form-errors [control]="sponsorForm.controls.lastName" class="mt-1" />
                    </div>
                </div>

                <div class="row">
                    <div class="col mx-auto">
                        <mat-form-field>
                            <mat-label>Téléphone</mat-label>
                            <input formControlName="mobile" id="mobile" matInput
                                   placeholder="Telephone"
                                   type="tel">
                            <em aria-hidden="true" class="fas fa-phone me-2 text-secondary" matPrefix></em>
                        </mat-form-field>
                        <app-form-errors [control]="sponsorForm.controls.mobile" class="mt-1" />
                    </div>
                </div>

                <div class="row">
                    <div class="col mx-auto">
                        <mat-form-field>
                            <mat-label>Email</mat-label>
                            <input formControlName="email" id="email" matInput placeholder="Email"
                                   type="email">
                            <em aria-hidden="true" class="fas fa-envelope me-2 text-secondary" matPrefix></em>
                        </mat-form-field>
                        <app-form-errors [control]="sponsorForm.controls.email" class="mt-1" />
                    </div>
                </div>

                @if (sponsorService.sponsorshipUser) {
                    <div class="row">
                        <div class="col mx-auto my-4">
                            @if (sponsorshipService.settings.templateHash !== 'CADS9488E2A7BB9515D') {
                                <p class="mb-3">
                                    Votre enregistrement sur la plateforme de parrainage vous permettra d’être régulièrement
                                    tenu informé(e) par email des nouvelles récompenses, résultats de vos actions et
                                    moyens de parrainer sans effort.
                                </p>
                            }
                            <mat-checkbox color="primary" [(ngModel)]="allowCommunications" [ngModelOptions]="{standalone: true}">
                                <mat-label class="px-2 text-medium clickable text-wrap">
                                    Je souhaite recevoir les nouveautés relatives au programme de parrainage.
                                </mat-label>
                            </mat-checkbox>
                        </div>
                    </div>
                }

                <div class="row">
                    <div class="col">
                        @if (loading) {
                            <button class="btn btn-primary mt-2 px-4 py-2" disabled>
                                <em aria-hidden="true" class="fas fa-spinner fa-spin"></em>
                            </button>
                        } @else {
                            @if (button === 'save') {
                                <button (click)="submit()"
                                    class="btn btn-primary mt-2 p-3 text-nowrap"
                                    data-cy="submit"
                                    name="button"
                                    type="button">Enregistrer
                                </button>
                            } @else if (button === 'Mis à jour !') {
                                <button class="btn btn-primary mt-2 p-3 text-nowrap"
                                    disabled="disabled" name="button">Mis à jour !
                            </button>
                            }
                        }
                    </div>
                </div>
            </form>
        </div>

        <app-footer />
    </div>
}
