import {Component} from '@angular/core';
import {SponsorshipSettings} from '@isifid/core';
import {Router} from '@angular/router';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';

@Component({
    templateUrl: './CACEE9528800D4243DD.component.html'
})
export class CACEE9528800D4243DDComponent {
    private settings: SponsorshipSettings;

    constructor(
    private readonly router: Router,
    private readonly sponsorService: SponsorService,
    private readonly sponsorshipService: SponsorshipService
    ) {
        this.settings = sponsorshipService.getSettings();
    }

    referralCreation(): void {
        if (this.sponsorService.sponsorshipUser) this.router.navigate(['/parrain/inviter']).then();
        else this.router.navigate(['/parrain/compte/creation']).then();
    }
}
