<app-header />

@if (!sponsorCreation) {<app-sponsor-shared-banner />}

<div class="col-12 m-0 mb-auto py-sm-3 py-md-5 pb-sm-5 px-0 px-xl-5 d-flex flex-row justify-content-center">
    <div class="col-12 col-sm-9 col-md-10 col-lg-7 col-xl-6 px-0 pt-3 pb-0 text-center h-100 px-0 me-lg-4">
        <div class="step col mx-auto p-0 pb-5">

            <div class="row px-5 mb-5">
                <div class="col px-md-5 text-center">
                    <h1 class="mt-5 h2 custom-text">Soyez unique !</h1>
                </div>
            </div>

            <div class="row px-5">
                <div class="col px-md-5 text-center">
                    <p class="text-secondary h6">
                        Vous avez la possibilité de personnaliser votre code parrain. Vos amis le retiendront plus
                        facilement et vous augmenterez vos récompenses.
                        <br><br>
                        Pourquoi ne pas choisir par exemple votre numéro de téléphone ou un pseudo connu ?
                    </p>
                </div>
            </div>

            <form [formGroup]="codeForm" class="my-5 px-5">
                <div class="row my-3">
                    <div class="col mx-auto">
                        <div class="form mx-auto text-start border-bottom">
                            <mat-label>Code parrain personnalisé</mat-label>
                            <input class="w-100"
                                   formControlName="codeCustomised"
                                   id="codeCustomised"
                                   maxlength="50"
                                   matInput>
                        </div>
                        <app-form-errors [control]="codeForm.controls.codeCustomised" class="mt-1" />
                    </div>
                </div>
            </form>

            <div class="row mt-4">
                <div class="col">
                    @if (sponsorCreation) {
                        <button (click)="later()"
                                class="btn btn-primary m-2 p-3 text-nowrap"
                                data-cy="later"
                                name="button">
                            Plus tard
                        </button>
                    }
                    <button (click)="setCustomCode()"
                            class="btn btn-primary m-2 p-3 text-nowrap"
                            name="button">
                        Mettre à jour
                    </button>
                </div>
            </div>

        </div>
    </div>

</div>

<app-footer />
